<template>
  <v-card outlined>
    <v-card-title>
      {{ currentRouteName }}

      <v-spacer></v-spacer>
      <v-btn depressed color="info"> add new invoice </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-data-table :headers="headers" :items="invoices">
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      headers: [
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Order Number",
          value: "orderNumber",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Company",
          value: "companyName",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Supplier",
          value: "supplierName",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Total Cost",
          value: "totalCost",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Products Quantity",
          value: "totalProductQty",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['invoices']),
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions(['getAllInvoices']),
  },
  created(){
    const self = this;
    self.getAllInvoices();
  }
};
</script>

<style>
</style>